import * as React from 'react'
import Layout from '@/components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import Breadcrumbs from '@/components/Common/Breadcrumbs'
import InfoContact from '@/components/Contact/Info'
import MapContact from '@/components/Contact/Map'


const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" canonical={`${useLocation().host}`} />
      <Breadcrumbs title="Contact Us" />
     <InfoContact/>
     <MapContact/>
    </Layout>
  )
}

export default ContactPage
