import React from 'react'
import Form from '../Form'

const MapContact = () => {
  return (
    <div className="map-section margin-top-90" style={{ marginBottom: '60px' }}>
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="contact_map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3588.9376895542155!2d-80.33079168602711!3d25.90441880900304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bb2cf22170a1%3A0xc8e4e283925d83b4!2s14411%20Commerce%20Way%20%23220%2C%20Hialeah%2C%20FL%2033016!5e0!3m2!1ses!2sus!4v1677613843143!5m2!1ses!2sus"
                style={{ border: 0, width: '100%', height: '100%' }}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              ></iframe>
            </div>
          </div>
          <Form />
        </div>
      </div>
    </div>
  )
}

export default MapContact
