import React from 'react'

const InfoContact = () => {
  return (
    <section className="contact-section-area margin-top-120">
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-4">
            <div className="icon-box-item-02">
              <div className="icon">
                <i className="fas fa-phone"></i>
              </div>
              <div className="content">
                <h5 className="title">Phone</h5>
                <p className="mb-3">
                  We will be happy to assist you. <br /> Please call us, we will work with you,{' '}
                  <br /> not for you.
                </p>
                <p className="mb-1">
                  Office Phone:
                  <span>
                    <a href="tel:+13058233561"> (305)823-3561</a>
                  </span>
                </p>
                <p className="mb-0">
                  Office Fax:
                  <span>
                    <a href='fax:+13056984026'> (305)698-4026 </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="icon-box-item-02">
              <div className="icon">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="content">
                <h5 className="title">Email</h5>
                <p className="mb-4">
                  Do not hesitate to contact us. We have an excellent team ready for response all
                  your questions!
                  <br />
                  <br />
                </p>
                <span>
                  <a href="mailto:info@perfectcareinc.net">info@perfectcareinc.net</a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="icon-box-item-02">
              <div className="icon">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="content">
                <h5 className="title">Address</h5>
                <p className="mb-4">
                  You can find us at this address. <br />
                  <br />
                  <br />
                  <br />
                </p>
                <span>
                  <a target="_blank" href="https://goo.gl/maps/Dxbo6p6r2uCdW46fA">
                    View On Google map
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InfoContact
